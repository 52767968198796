import { firebase } from "@/lib/firebase/client";
import { NextRouter, useRouter } from "next/router";
import { useStore } from "../store";

export const isSignInWithEmailLink = (email: string) => firebase.auth().isSignInWithEmailLink(email)

export const signInWithEmailLink = (email: string) => firebase.auth().signInWithEmailLink(email, window.location.href)

export const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('email');
    return firebase.auth().signInWithRedirect(provider)
};

export const signInWithTwitter = async () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithRedirect(provider)
};


export const signInWithEmailAndPassword = async (email: string, password: string) => {
    return firebase.auth().signInWithEmailAndPassword(email, password)
};

export const signUpWithEmailAndPassword = async (email: string, password: string) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password)
    // return firebase.auth().currentUser?.sendEmailVerification(email);
};

export const updateName = async (name: string, photo: string) => {
    return await firebase.auth().currentUser?.updateProfile({displayName: name, photoURL: photo})
};

export const getRedirectResult = async () => firebase.auth().getRedirectResult();

export const signInWithApple = async () => {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    const response = await firebase.auth()
        .signInWithPopup(provider);
    console.log('response ', response)
    window.location.reload();
    // let token = (await response.user.getIdToken());
    // let res = await setSession({info: response.additionalUserInfo}, token);
    // console.log('session res :: ', await res.json());
    // return await getUserAdditionalData(token);
};

export const signInWithEmail = (email: string) => {
    return firebase.auth()
        .sendSignInLinkToEmail(email, {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: `https://${window.location.host}/loginPasswordLess`,
            // This must be true.
            handleCodeInApp: true
        });
};


export const signOut = async () => {
    await fetch(`${window.location.origin}/api/auth/session`, {
        method: 'delete'
    });
    await firebase.auth().signOut();
    window.location.href = '/login'
};

const exchangeToken = async (idToken: string) => {
    const res = await fetch(`${window.location.origin}/api/auth/session`, {
        method: 'post',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken })
    });
    return await res.json()
}

export const authStateChanges = async (store: any) => {
    if (!firebase.apps.length) return;
    return await firebase.auth().onIdTokenChanged(async (user) => {
        if (user) {
            let idToken = (await user.getIdToken());
            let res = await exchangeToken(idToken);
            if (res.data && !store.getState().session) {
                await store.setState({
                    session: {
                        user: {
                            uid: res.data.uid,
                            email: res.data.email,
                            name: res.data.name,
                            avatar: res.data.avatar,
                        }
                    }
                });
                if (window.location.href.indexOf('/login') > -1) {
                    window.location.href = '/'
                }
            }
        } else {
            await fetch(`${window.location.origin}/api/auth/session`, {
                method: 'delete'
            });
            return null;
        }
    });
}