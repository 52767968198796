import { useCreateStore, Provider } from "@/lib/store";

import { ModalProvider } from "@/lib/hooks/useModal";

import "@/styles/globals.css";

import type { AppProps } from "next/app";
import { authStateChanges } from "@/lib/auth/client";

import NextProgress from "next-progress";

import {
  AnimatePresence,
  domAnimation, LazyMotion,
  m
} from "framer-motion"

import { DefaultSeo } from "next-seo";
import SEO from '../next-seo.config';

import { useEffect, useState } from "react";

import { AnalyticsProvider } from 'use-analytics'

import Analytics from 'analytics'

import splitbeePlugin from "analytics-plugin-splitbee"
// import mixpanelPlugin from '@analytics/mixpanel'

import Router, { useRouter } from "next/router";


const analytics = Analytics({
  app: 'curious-page',
  plugins: [
    /* mixpanelPlugin({
      token: "74ee72f2b26a0de50182422632baef27"
    }), */
    splitbeePlugin({
      scriptUrl: "/bee.js",
      token: "2LYH12G8E7HO",
      apiUrl: "/_hive"
    })
  ]
})

/* // Track initial pageview
if (typeof window !== "undefined") {
  console.log("window.location", window.location.href)
  analytics.page();
}

// Track pageview on route change
Router.events.on("routeChangeComplete", (url) => {
  console.log("routeChangeComplete", url)
  analytics.page();
}); */

const fadeBack = {
  name: "Fade Back",
  variants: {
    initial: {
      opacity: 0,
      scale: 0.4
    },
    animate: {
      opacity: 1,
      scale: 1
    },
    exit: {
      opacity: 0,
      scale: 0.4
    }
  },
  transition: {
    duration: 0.7
  }
};


export default function App({
  Component,
  pageProps: { session, ...pageProps },
  router
}: AppProps) {
  const createStore = useCreateStore(pageProps.initialZustandState);
  authStateChanges(createStore());


  return (
    <>
      <DefaultSeo {...SEO} />
      <Provider createStore={createStore}>
        <ModalProvider>
          <NextProgress delay={300} options={{ showSpinner: true }} height="4px" color="#374151" />

          <AnalyticsProvider instance={analytics}>
            <Component {...pageProps} />
          </AnalyticsProvider>
          {/* <LazyMotion features={domAnimation}>
              <AnimatePresence exitBeforeEnter={!exitBefore} >
                <m.div
                  key={router.route.concat(fadeBack.name)}
                  className="page-wrap"
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={fadeBack.variants}
                  transition={fadeBack.transition}
                >
                  <Component {...pageProps} />
                </m.div>
              </AnimatePresence>
            </LazyMotion> */}
        </ModalProvider>
      </Provider>
    </>
  );
}
