export default {
  defaultTitle: 'Curious.page — Super simple site builder',
  description: 'Curious.page is a blazing fast, simple, and free tool for building websites.',
  canonical: 'https://curious.page',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://curious.page/',
    site_name: 'Curious.page',
    images: [
      { url: 'https://curious.page/og-image.png' },
    ]
  },
  twitter: {
    handle: '@olucurious',
    site: '@curious_page',
    twitter_user: '@olucurious',
    cardType: 'summary_large_image',
  },
};