import React, { createContext, useContext, useEffect, useState } from 'react';

import dynamic from 'next/dynamic';

const GenericModal = dynamic(import("@/components/modals/Generic"), { ssr: false })

const useModalProvider = () => {
    let [modal, setModal] = useState<boolean>(false);
    let [title, setTitle] = useState<string | JSX.Element>('');
    let [children, setChildren] = useState<JSX.Element>();

    let openModal = (content: JSX.Element | undefined, title: string | JSX.Element = '') => {
        //setModal(!modal);
        if (content) {
            setTitle(title);
            setChildren(content);
            setModal(true);
        } else {
            setModal(false);
        }
    };
    return { modal, openModal, closeModal: () => {
        setModal(false)
        setChildren(<></>)
    }, children, title };
};

interface ModalContextType {
    modal: boolean;
    openModal: (content?: JSX.Element, title?: string | JSX.Element) => void;
    closeModal: () => void;
    children: JSX.Element | undefined;
    title: string | JSX.Element;
}

const ModalContext = createContext<ModalContextType | null>(null);

export const ModalProvider: React.FC<React.ReactNode> = ({ children }) => {
    const modalProvider = useModalProvider();
    return (
        <ModalContext.Provider value={modalProvider}>
            <GenericModal />
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext) as ModalContextType;